import { type ActionFunctionArgs, redirect, type LoaderFunctionArgs, type TypedResponse } from '@remix-run/node'
import { Form, useActionData, useNavigation } from '@remix-run/react'
import { useState } from 'react'
import { useValidatedInput, validateServerFormData, type InputDefinition } from 'remix-validity-state'
import logo from '~/assets/logo/logobleu.png'
import { Error } from '~/components/alerts/error.tsx'
import Input from '~/components/form/Input.tsx'
import InputContainer from '~/components/form/InputContainer.tsx'
import Label from '~/components/form/Label.tsx'
import { Spinner } from '~/components/Spinner.tsx'
import { Button } from '~/components/ui/Button.tsx'
import { Icon } from '~/components/ui/icon.tsx'
import { getUrls } from '~/constants/navUrls.ts'
import { useIsMobile } from '~/hooks/useIsMobile.ts'
import { getClientServices } from '~/models/services.server.ts'
import { login } from '~/utils/authentication.server.ts'
import { badRequest } from '~/utils/request.server.ts'
import { getUserFromSession, setUserWithFiltersInSession } from '~/utils/session.server.ts'
import { Text } from './_marketing+/components/text.tsx'

export const loader = async ({ request }: LoaderFunctionArgs) => {
	const user = await getUserFromSession(request)
	if (!user) return null
	if (user.licence === 'Externe' || user.licence === 'Gestionnaire' || user.licence === 'Attributeur') return redirect('/demandes/dashboard')
	const { dashboardUrl } = getUrls()
	return redirect(dashboardUrl)
}

type FormSchema = {
	inputs: {
		email: InputDefinition
		password: InputDefinition
	}
}

const formDefinition = {
	inputs: {
		email: {
			validationAttrs: {
				type: 'email',
				required: true,
			},
		},
		password: {
			validationAttrs: {
				minLength: 8,
				required: true,
			},
		},
	},
} satisfies FormSchema

type ActionData = {
	fields?: {
		email: string
		password: string
	}
	formError?: string
}

type ThrowActionData = {
	error: string
}

export default function Login() {
	const { fields, formError } = useActionData<ActionData>() || {}
	const emailInput = useValidatedInput({ formDefinition, name: 'email' })
	const passwordInput = useValidatedInput({ formDefinition, name: 'password' })
	const [showPassword, setShowPassword] = useState(false)
	const isMobile = useIsMobile()
	const navigation = useNavigation()
	const isSubmitting = navigation.state !== 'idle'

	return (
		<div className="flex w-full justify-center">
			<div className="m-4 flex max-w-xs  flex-col items-center rounded-xl bg-white p-4 shadow-lg xl:max-w-md 2xl:m-8 3xl:m-16 3xl:max-w-xl">
				<img src={logo} alt="" className="w-3/4 2xl:w-full" />
				<Text variant="Text3" tagType="h1" className="mt-2 min-w-max text-center font-medium text-muted-600">
					Pour une ville plus belle et plus durable
				</Text>

				<Form method="post" className="mt-2 grid w-full gap-4 2xl:mt-4 3xl:gap-8">
					<InputContainer>
						<Label {...emailInput.getLabelAttrs()}>Email</Label>
						<Input {...emailInput.getInputAttrs()} defaultValue={fields?.email} autoFocus />
					</InputContainer>

					<InputContainer>
						<Label {...passwordInput.getLabelAttrs()}>Mot de passe</Label>
						<div className={`relative flex w-full text-sm`}>
							<Input {...passwordInput.getInputAttrs()} type={showPassword ? 'text' : 'password'} defaultValue={fields?.password} className="pr-10" />
							<div className="absolute inset-y-0 right-0 z-10 flex cursor-pointer items-center pr-3" onClick={() => setShowPassword((prev) => !prev)}>
								{showPassword ? <Icon name="eye-none" className="hover h-5 w-5 text-gray-400" aria-hidden="true" /> : <Icon name="eye" className="h-5 w-5 text-gray-400" aria-hidden="true" />}
							</div>
						</div>
					</InputContainer>

					{formError ? <Error className="mt-4 w-full">{formError}</Error> : null}

					<div className="mt-2 flex w-full flex-col items-center justify-center gap-4">
						{isSubmitting ? (
							<Spinner />
						) : (
							<>
								<Button name="_action" value={'app'} variant="primary-degrade" type="submit" className="w-full">
									Connexion à l'application {isMobile ? 'web' : ''}
								</Button>
								{isMobile ? (
									<Button name="_action" value={'mobile'} variant="primary-degrade" type="submit" className="w-full">
										Connexion à l'application mobile
									</Button>
								) : null}
							</>
						)}
					</div>
				</Form>
			</div>
		</div>
	)
}

export const action = async ({ request }: ActionFunctionArgs): Promise<TypedResponse<ActionData | never>> => {
	const formData = await request.formData()
	const _action = formData.get('_action')
	const {
		valid,
		submittedValues: { email, password },
	} = await validateServerFormData(formData, formDefinition)

	if (password.length < 8)
		return badRequest({
			fields: { email, password },
			formError: 'Le mot de passe doit faire au moins 8 caractères',
		})

	if (!valid) {
		throw badRequest<ThrowActionData>({ error: `Données invalides` })
	}

	// Check if email and password are correct
	const user = await login({ email, password })
	if (!user) {
		return badRequest({
			fields: { email, password },
			formError: 'Identifiant ou mot de passe incorrect',
		})
	}
	const services = await getClientServices(user.clientId)
	if (_action === 'mobile') {
		const userTeams = user.teams.length > 0 ? [user.teams[0].id] : []
		const userService = user.services[0]?.id
		if (!userService) return badRequest({ formError: 'Service non trouvé' })
		const { mobileUrl } = getUrls()
		return setUserWithFiltersInSession({ user, teamIds: userTeams, serviceId: userService, redirectTo: mobileUrl })
	} else {
		if (user.licence === 'Externe' || user.licence === 'Gestionnaire' || user.licence === 'Attributeur') {
			return setUserWithFiltersInSession({ user, teamIds: [], serviceId: services[0]?.id, redirectTo: '/app/demandes' })
		} else {
			const userTeams = user.teams.length > 0 ? [user.teams[0].id] : []
			const userService = user.services[0]?.id
			if (!userService) return badRequest({ formError: 'Service non trouvé' })
			const { dashboardUrl } = getUrls()
			return setUserWithFiltersInSession({ user, teamIds: userTeams, serviceId: userService, redirectTo: dashboardUrl })
		}
	}
}
