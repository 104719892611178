import { useState, useEffect } from 'react'

export const useIsMobile = (breakpoint = 1024) => {
	const [isMobile, setIsMobile] = useState<boolean | null>(null) // null during SSR

	useEffect(() => {
		const check = () => {
			setIsMobile(window.innerWidth <= breakpoint)
		}
		check()
		window.addEventListener('resize', check)
		return () => window.removeEventListener('resize', check)
	}, [breakpoint])

	return isMobile
}
